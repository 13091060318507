import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TourNavigation from './TourNavigation';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 42,
  },
}));

const TourStepComponent = React.memo(props => {
  const { title, description } = props;
  const classes = useStyles();

  return (
    <Box>
      <Typography
        variant="h5"
        color="inherit"
        component="div"
        className={classes.title}
      >
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      <Typography
        variant="h6"
        color="inherit"
        component="p"
        className={classes.description}
      >
        {description}
      </Typography>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TourNavigation {...props} />
    </Box>
  );
});

TourStepComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TourStepComponent;
