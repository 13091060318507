import React, { useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  Card,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OnboardPageCloudsIcon from './OnboardPageCloudsIcon';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    width: '100%',
    minHeight: 483,
    background: '#fff',
    margin: '26px 24px 0 24px',
    boxShadow: 'none',
    borderRadius: 10,
  },
  clouds: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    zIndex: 5,
  },
  cardContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxWidth: 550,
    textAlign: 'center',
    margin: '0 auto',
    zIndex: 10,
  },
  title: {
    marginTop: 37,
    display: 'block',
    width: '100%',
  },
  iconWrapper: {
    display: 'block',
    width: '100%',
  },
  icon: {
    width: ({ iconWidth }) => iconWidth,
    height: 'auto',
  },
  noIcon: {
    width: ({ iconWidth }) => iconWidth,
    height: ({ iconWidth }) => iconWidth,
    background: theme.palette.grey[200],
    borderRadius: '100%',
    margin: '0 auto',
  },
  info: {
    margin: '14px 0 30px 0',
  },
}));

const OnboardPage = props => {
  const { title, info, icon: Icon, showOnboardStepper, children } = props;
  const { saveOnboardLoading, saveOnboardSuccess } = useSelector(
    state => state.onboardState
  );
  const classes = useStyles(props);
  const history = useHistory();

  useEffect(() => {
    if (!showOnboardStepper && saveOnboardSuccess) {
      history.go(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveOnboardSuccess, showOnboardStepper]);

  return (
    <Grid container item>
      <Card className={classes.card}>
        <OnboardPageCloudsIcon className={classes.clouds} />
        <Grid
          container
          item
          className={classes.cardContent}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Box className={classes.iconWrapper}>
            {Icon ? (
              <Icon className={classes.icon} />
            ) : (
              <Box className={classes.noIcon} />
            )}
          </Box>
          <Typography variant="h4" component="div" className={classes.title}>
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
          <Typography variant="h6" component="div">
            <Box className={classes.info} fontWeight="fontWeightRegular">
              {info}
            </Box>
          </Typography>
          {saveOnboardLoading || saveOnboardSuccess ? (
            <Grid container item justifyContent="center">
              <CircularProgress size={24} color="inherit" />
            </Grid>
          ) : (
            children
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

OnboardPage.defaultProps = {
  icon: null,
  iconWidth: 203,
};

OnboardPage.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  icon: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  iconWidth: PropTypes.number,
  showOnboardStepper: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default OnboardPage;
