import React, { useEffect } from 'react';
import {
  Grid,
  makeStyles,
  withStyles,
  Typography,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../../../../components/elements/Card';
import EmptyCreditLine from './EmptyCreditLine';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import { fetchCreditLine } from '../../../../store/actions/creditLineActions';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    padding: '24px 16px 0 16px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  balanceAmount: {
    fontSize: '24px',
    lineHeight: '48px',
    color: theme.palette.primary.main,
  },
  amountTitle: {
    margin: '4px 0',
  },
  bar: {
    margin: '11px 0 4px 0',
  },
}));

const CreditLineLinearProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const CreditLine = () => {
  const classes = useStyles();
  const { countryId } = useSelector(state => state.businessState.business);
  const { creditLine, creditLineLoading } = useSelector(
    state => state.creditLineState
  );
  const { id: businessId } = useSelector(state => state.businessState.business);
  const {
    creditLine: { creditAmount, balance },
  } = useSelector(state => state.creditLineState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCreditLine(businessId));
  }, [businessId, dispatch]);

  return (
    <Grid item>
      <Card variant="white" border className={classes.card}>
        {Object.entries(creditLine).length > 0 && !creditLineLoading ? (
          <Grid item direction="column" container>
            <Grid item>
              <Typography
                variant="body2"
                className={classes.title}
                component="div"
              >
                <Box fontWeight="fontWeightMedium">Saldo</Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                className={classes.balanceAmount}
                component="div"
              >
                <Box fontWeight="fontWeightMedium">
                  <CountryFormatHelper
                    value={balance}
                    countryId={countryId}
                    variant="currency"
                  />
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <Grid item className={classes.bar}>
                <CreditLineLinearProgress
                  variant="determinate"
                  value={((creditAmount - balance) * 100) / creditAmount}
                />
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="caption" className={classes.amountTitle}>
                    Crédito Utilizado
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    <Box fontWeight="fontWeightBold">
                      <CountryFormatHelper
                        value={creditAmount - balance}
                        countryId={countryId}
                        variant="currency"
                      />
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Box textAlign="right">
                    <Typography
                      variant="caption"
                      className={classes.amountTitle}
                    >
                      Línea de crédito
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      <Box fontWeight="fontWeightBold">
                        <CountryFormatHelper
                          value={creditAmount}
                          countryId={countryId}
                          variant="currency"
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <EmptyCreditLine />
        )}
      </Card>
    </Grid>
  );
};

export default CreditLine;
