import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import SchedulePaymentStep1 from './SchedulePaymentStep1';
import SchedulePaymentStep2 from './SchedulePaymentStep2';
import SchedulePaymentStep3 from './SchedulePaymentStep3';
import SchedulePaymentSuccess from './SchedulePaymentSuccess';
import { schedulePaymentModalStyles } from './styles';
import { StepperContainer } from '../StepperFlow';

const SchedulePaymentModal = React.memo(props => {
  const { open, closeModal } = props;
  const classes = schedulePaymentModalStyles();

  const SCHEDULE_STEPS = [
    {
      label: 'Fecha de pago',
      component: <SchedulePaymentStep1 />,
    },
    {
      label: 'Facturas',
      component: <SchedulePaymentStep2 />,
    },
    {
      label: 'Resumen',
      component: <SchedulePaymentStep3 />,
      customButtons: true,
    },
  ];

  const SCHEDULE_STEPS_COMPLETED = {
    component: <SchedulePaymentSuccess />,
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
      disablePortal
      className={classes.modal}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade style={{ padding: 0 }} in={open}>
        <div className={classes.paper}>
          <StepperContainer
            title="Programación de facturas"
            closeModal={closeModal}
            steps={SCHEDULE_STEPS}
            stepCompleted={SCHEDULE_STEPS_COMPLETED}
          />
        </div>
      </Fade>
    </Modal>
  );
});

SchedulePaymentModal.defaultProps = {};

SchedulePaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SchedulePaymentModal;
