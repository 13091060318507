import React from 'react';
import { useHistory } from 'react-router-dom';

import { OnboardPage } from '../../../commons/components';
import BoardQuestionMarkIcon from './BoardQuestionMarkIcon';
import CustomButton from '../../../../components/elements/CustomButton';

const OnboardFillProfile = React.memo(() => {
  const history = useHistory();
  return (
    <OnboardPage
      icon={BoardQuestionMarkIcon}
      title="Completa tu perfil"
      info="Ingresa tus credenciales del SAT y ve aquí tus facturas disponibles para poder pagar a tus proveedores."
    >
      <CustomButton
        variant="all-orange"
        color="secondary"
        size="large"
        onClick={() => history.push(`/finance`)}
      >
        Empezar
      </CustomButton>
    </OnboardPage>
  );
});

export default OnboardFillProfile;
