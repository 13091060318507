import React, { useEffect } from 'react';
import { Grid, Icon } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StatElement from './StatElement';
import {
  InboxIcon,
  ScheduledIcon,
  LatePaymentIcon,
} from '../../../commons/icons';
import { paymentsFetchStats } from '../../../../store/actions/paymentsStatsActions';
import { CreditLine } from '../../../commons/components';
import { statsStyles } from './styles';

const Stats = React.memo(() => {
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { stats, isStatsLoading } = useSelector(
    state => state.paymentsStatsState
  );
  const classes = statsStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentsFetchStats(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.root}>
      <CreditLine />
      <StatElement
        title="Pendientes"
        icon={<Icon component={InboxIcon} />}
        stats={stats?.pending}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <StatElement
        title="Programadas"
        icon={<Icon component={ScheduledIcon} />}
        stats={stats?.scheduled}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <StatElement
        title="Atrasadas"
        icon={<Icon component={LatePaymentIcon} />}
        stats={stats?.delayed}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
    </Grid>
  );
});

export default Stats;
