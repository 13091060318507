import React from 'react';
import { makeStyles, Typography, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const DEFAULT_PAYMENT_TERM = 45;

const useStyles = makeStyles(() => ({
  providerField: {
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  supplierNameItem: {
    width: '60%',
  },
  identifierItem: {
    width: '25%',
  },
  paymentTermItem: {
    width: '15%',
    textAlign: 'center',
  },
  paymentTermInput: {
    margin: 0,
  },
  emptySuppliers: {
    marginTop: 10,
  },
}));

const OnboardStep2Table = props => {
  const { supplierData, customPaymentTerms, setCustomPaymentTerms } = props;
  const classes = useStyles();

  const changePaymentTerm = (evt, identifier) => {
    const newPaymentTerm = evt.currentTarget.value;
    setCustomPaymentTerms({
      ...customPaymentTerms,
      [identifier]: {
        paymentTerm: newPaymentTerm,
      },
    });
  };

  if (supplierData.length <= 0) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.emptySuppliers}
      >
        <Grid item>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.providerField}
          >
            No se han encontrado proveedores con términos de pagos.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container item spacing={1}>
      {supplierData.map(({ name, identifier, paymentTerm = null }) => (
        <Grid
          key={identifier}
          container
          item
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item className={classes.supplierNameItem}>
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.providerField}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item className={classes.identifierItem}>
            <Typography variant="body2" color="textPrimary">
              {identifier}
            </Typography>
          </Grid>
          <Grid item className={classes.paymentTermItem}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              color="secondary"
              onChange={evt => changePaymentTerm(evt, identifier)}
              value={
                customPaymentTerms[identifier]?.paymentTerm ||
                paymentTerm ||
                DEFAULT_PAYMENT_TERM
              }
              className={classes.paymentTermInput}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

OnboardStep2Table.propTypes = {
  supplierData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      identifier: PropTypes.string,
      paymentTerm: PropTypes.number,
    })
  ).isRequired,
  customPaymentTerms: PropTypes.objectOf(PropTypes.object).isRequired,
  setCustomPaymentTerms: PropTypes.func.isRequired,
};

export default OnboardStep2Table;
