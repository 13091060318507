import { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StepperContext } from '../../StepperFlow';
import { paymentsSavePayroll } from '../../../../../store/actions/paymentsActions';
import { SEGMENT_EVENTS } from '../../../../../helpers/segment/constants';
import { trackSegment } from '../../../../../helpers/segment/trackingSegment';

const useSchedulePaymentStep3 = () => {
  const { state, currentStep, goBack, goNext } = useContext(StepperContext);
  const { payrollPaymentDate } = state;

  const [invoicesQuantities, setInvoicesQuantities] = useState(0);
  const [providersQuantities, setProvidersQuantities] = useState(0);

  const { selectedInvoices, totalSelectedInvoices } = useSelector(
    ({ paymentsState }) => paymentsState
  );
  const { id: businessId, countryId } = useSelector(
    ({ businessState }) => businessState.business
  );
  const { savePayrollLoading } = useSelector(
    ({ paymentsState }) => paymentsState
  );
  const dispatch = useDispatch();

  const handleSavePayroll = () => {
    const { payrollPaymentDate: paymentDate, payrollName: name } = state;
    const invoiceIds = selectedInvoices.map(invoice => invoice.id);
    dispatch(
      paymentsSavePayroll(businessId, name, paymentDate, invoiceIds, goNext)
    );
    trackSegment(SEGMENT_EVENTS.PAYMENT_ORDER_SCHEDULED, {
      businessId,
    });
  };

  return {
    currentStep,
    goBack,
    payrollPaymentDate,
    invoicesQuantities,
    setInvoicesQuantities,
    providersQuantities,
    setProvidersQuantities,
    selectedInvoices,
    totalSelectedInvoices,
    businessId,
    countryId,
    savePayrollLoading,
    handleSavePayroll,
  };
};

export default useSchedulePaymentStep3;
