import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Slider,
  Typography,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  StepperTitle,
  StepperSubtitle,
  StepperContext,
} from '../../../../commons/components';
import LoadingButton from '../../../../../components/elements/LoadingButton';
import { fetchOnboardSuppliers } from '../../../../../store/actions/onboardActions';
import { enqueueSnackbar } from '../../../../../store/actions/notificationActions';
import { t13s } from '../../../../../translationKeys';

const DEFAULT_VALUE = 60;
const MIN_SLIDER_VALUE = 15;
const MAX_SLIDER_VALUE = 90;

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    marginBottom: 40,
  },
  input: {
    width: 92,
    margin: 0,
  },
  inputMonthText: {
    marginLeft: 20,
  },
  innerInput: {
    textAlign: 'center',
    fontSize: 28,
    padding: '12px 0',
  },
  rail: {
    borderRadius: 10,
    height: 4,
  },
  track: {
    borderRadius: 10,
    height: 4,
  },
  thumb: {
    width: 19,
    height: 19,
    background: '#fff',
    border: `4px solid ${theme.palette.primary.main}`,
    marginTop: -7,
  },
  buttonsRoot: {
    marginTop: 28,
    background: '#fff',
    position: 'sticky',
    bottom: 0,
    padding: '11px 0',
  },
}));

const OnboardStep1 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updateState, goNext } = useContext(StepperContext);
  const [sliderValue, setSliderValue] = useState(DEFAULT_VALUE);
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { suppliers, fetchOnboardSuppliersLoading } = useSelector(
    state => state.onboardState
  );

  const gotoPaymentTerms = () => {
    if (sliderValue < MIN_SLIDER_VALUE || sliderValue > MAX_SLIDER_VALUE) {
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.ONBOARD_DAYS_OUT_OF_RANGE,
          options: {
            variant: 'error',
          },
        })
      );
    } else {
      const onboardDate = moment()
        .subtract(sliderValue, 'days')
        .format('YYYY-MM-DD');
      dispatch(fetchOnboardSuppliers(businessId, onboardDate));
      updateState({
        onboardDate,
      });
    }
  };

  const changeSliderValue = event => {
    const { value } = event.currentTarget;
    const parsedValue = Number(value);
    setSliderValue(parsedValue);
  };

  const handleSliderChange = (event, value) => {
    const parsedValue = Number(value);
    setSliderValue(parsedValue);
  };

  useEffect(() => {
    if (suppliers.length) {
      goNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers]);

  return (
    <Grid container item>
      <StepperTitle>
        ¿Desde cuándo quieres sincronizar tus facturas?
      </StepperTitle>
      <StepperSubtitle>
        Elije desde cuando quieres sincronizar tus facturas.
      </StepperSubtitle>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.inputWrapper}
      >
        <Grid item>
          <TextField
            variant="outlined"
            className={classes.input}
            value={sliderValue}
            onChange={changeSliderValue}
            InputProps={{
              classes: {
                input: classes.innerInput,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            color="textPrimary"
            className={classes.inputMonthText}
          >
            {sliderValue === 1 ? 'día' : 'días'}
          </Typography>
        </Grid>
      </Grid>

      <Slider
        value={sliderValue}
        step={1}
        valueLabelDisplay="auto"
        min={MIN_SLIDER_VALUE}
        max={MAX_SLIDER_VALUE}
        onChange={handleSliderChange}
        classes={{
          rail: classes.rail,
          track: classes.track,
          thumb: classes.thumb,
        }}
      />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="p">
            {MIN_SLIDER_VALUE} días
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary" component="p">
            {MAX_SLIDER_VALUE} días
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.buttonsRoot}>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={fetchOnboardSuppliersLoading}
            isLoading={fetchOnboardSuppliersLoading}
            onClick={gotoPaymentTerms}
          >
            Continuar
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardStep1;
