import React from 'react';
import { Grid, makeStyles, Box, Typography, Link } from '@material-ui/core';
import logoSat from '../../../../assets/logosat.svg';

const useStyles = makeStyles(theme => ({
  logoSat: {
    marginRight: 12,
  },
  latestUpdate: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
  },
  latestUpdateDate: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    minWidth: 123,
    '& > div': {
      display: 'inline-block',
    },
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      display: 'inline-block',
    },
  },
}));

const SatCard = () => {
  const classes = useStyles();
  return (
    <Grid container item>
      <Box>
        <Grid container>
          <Grid item>
            <img
              src={logoSat}
              width="38"
              height="38"
              alt=""
              className={classes.logoSat}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              component="div"
              className={classes.latestUpdate}
            >
              <Box fontWeight="fontWeightBold">Última actualización</Box>
            </Typography>
            <Box>
              <Typography
                variant="body1"
                color="textPrimary"
                component="h6"
                className={classes.latestUpdateDate}
              >
                <Box fontWeight="fontWeightBold">6 Jul, 2021 ·&nbsp;</Box>
                <Link href="#">Sincronizar</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SatCard;
