import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ArrowRightIcon from '../../../../../components/icons/ArrowRightIcon';
import PayInvoicesBankTransferStep1 from './PayInvoicesBankTransferStep1';
import PayInvoicesAccountFinancingStep1 from './PayInvoicesAccountFinancingStep1';
import CustomButton from '../../../../../components/elements/CustomButton';
import StepperTitle from '../../StepperTitle';
import StepperSubtitle from '../../StepperSubtitle';

import { SEGMENT_EVENTS } from '../../../../../helpers/segment/constants';
import { trackSegment } from '../../../../../helpers/segment/trackingSegment';

const useStyles = makeStyles(theme => ({
  paymentMethodButton: {
    borderRadius: 16,
    width: '100%',
    padding: '22px 32px',
    textAlign: 'left',
  },
  paymentMethodArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    borderRadius: '100%',
    width: 24,
    height: 24,
  },
  paymentMethodArrowIcon: {
    width: 6,
    height: 9,
    color: 'white',
  },
  rootButton: {
    marginTop: 28,
    marginBottom: 28,
  },
  backButton: {
    marginRight: 22,
  },
}));

const PayInvoicesStep2 = props => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [nestedStep, setNestedStep] = useState(0);
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { payrollId, invoicesTotalAmount } = props;
  const classes = useStyles();

  const goNextNested = () => {
    setNestedStep(prev => prev + 1);
  };

  const goBackNested = () => {
    if (nestedStep <= 0) {
      setSelectedPaymentMethod(null);
    } else {
      setNestedStep(prev => prev - 1);
    }
  };

  const handlePaymentMethod = nestedFlow => {
    setSelectedPaymentMethod(nestedFlow);
    trackSegment(SEGMENT_EVENTS.PAYMENT_METHOD, {
      businessId,
      paymentMethod: nestedFlow,
    });
  };

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_PAY_NOW, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NESTED_FLOWS = {
    bankTransfer: [
      {
        component: (
          <PayInvoicesBankTransferStep1
            countryId={countryId}
            goBackNested={goBackNested}
            invoicesTotalAmount={invoicesTotalAmount}
            businessId={businessId}
            payrollId={payrollId}
          />
        ),
        customButtons: true,
      },
    ],
    accountFinancing: [
      {
        component: (
          <PayInvoicesAccountFinancingStep1
            countryId={countryId}
            goBackNested={goBackNested}
            invoicesTotalAmount={invoicesTotalAmount}
            payrollId={payrollId}
          />
        ),
        customButtons: true,
      },
    ],
  };

  if (selectedPaymentMethod !== null) {
    return (
      <Grid container item>
        {NESTED_FLOWS[selectedPaymentMethod][nestedStep].component}
        {!NESTED_FLOWS[selectedPaymentMethod][nestedStep].customButtons && (
          <Grid container className={classes.rootButton}>
            <Grid item>
              <CustomButton
                variant="simple-gray"
                color="secondary"
                size="large"
                onClick={goBackNested}
                className={classes.backButton}
              >
                Volver
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                variant="all-orange"
                color="secondary"
                size="large"
                onClick={goNextNested}
              >
                Siguiente
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container item>
      <StepperTitle>Método de pago</StepperTitle>
      <StepperSubtitle>
        Selecciona una de las opciones para realizar esta operación.
      </StepperSubtitle>

      <Button
        className={classes.paymentMethodButton}
        onClick={() => handlePaymentMethod('bankTransfer')}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" component="div">
              <Box fontWeight="fontWeightBold">Pagar con caja propia</Box>
            </Typography>
            <Typography variant="h6" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">Transferencia bancaria</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box className={classes.paymentMethodArrow}>
              <ArrowRightIcon className={classes.paymentMethodArrowIcon} />
            </Box>
          </Grid>
        </Grid>
      </Button>

      <Button
        className={classes.paymentMethodButton}
        onClick={() => handlePaymentMethod('accountFinancing')}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" component="div">
              <Box fontWeight="fontWeightBold">Solicitar financimiento</Box>
            </Typography>
            <Typography variant="h6" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                Financimiento de cuentas por pagar
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box className={classes.paymentMethodArrow}>
              <ArrowRightIcon className={classes.paymentMethodArrowIcon} />
            </Box>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};

PayInvoicesStep2.propTypes = {
  invoicesTotalAmount: PropTypes.number.isRequired,
  payrollId: PropTypes.number.isRequired,
};

export default PayInvoicesStep2;
