import React, { useContext } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import CustomButton from '../../../../components/elements/CustomButton';
import CheckIcon from '../../../../components/icons/CheckIcon';
import StepperTitle from '../StepperTitle';
import StepperSubtitle from '../StepperSubtitle';
import { StepperContext } from '../StepperFlow';

const useStyles = makeStyles(theme => ({
  buttonsRoot: {
    marginTop: 0,
  },
  check: {
    width: 48,
    height: 48,
    borderRadius: '100%',
    background: alpha(theme.palette.success.main, 0.2),
    marginBottom: 32,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));

const FLOW_TYPES = {
  bankTransfer: 'bankTransfer',
  accountFinancing: 'accountFinancing',
};

const PayInvoicesSuccess = () => {
  const {
    state: { flowType, paid },
    closeModal,
  } = useContext(StepperContext);
  const classes = useStyles();

  return (
    <Grid container item direction="column">
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        className={classes.check}
      >
        <CheckIcon className={classes.checkIcon} />
      </Grid>

      <StepperTitle>
        {flowType === FLOW_TYPES.bankTransfer &&
          paid &&
          'Listo! Estamos procesando tu pago'}
        {flowType === FLOW_TYPES.bankTransfer &&
          !paid &&
          'Estamos esperando tu pago'}
        {flowType === FLOW_TYPES.accountFinancing &&
          '¡Solicitud de financiamiento enviada con éxito!'}
      </StepperTitle>
      <StepperSubtitle>
        {flowType === FLOW_TYPES.bankTransfer &&
          paid &&
          'Ten en cuenta que la verificación de los pagos realizados fuera de horario laboral (Lunes a viernes de 8:00 a 16:00) podrían tomar hasta 48 horas.'}
        {flowType === FLOW_TYPES.bankTransfer &&
          !paid &&
          'Ten en cuenta que la verificación de los pagos realizados fuera de horario laboral (Lunes a viernes de 8:00 a 16:00) podrían tomar hasta 48 horas.'}
        {flowType === FLOW_TYPES.accountFinancing &&
          'Muy pronto, un asesor de Xepelin se pondrá en contacto contigo para hablarte más de este beneficio.'}
      </StepperSubtitle>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          <CustomButton
            variant="all-orange"
            color="secondary"
            size="large"
            onClick={closeModal}
          >
            Ir a pagos
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PayInvoicesSuccess;
