import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { StepperContext } from '../StepperFlow';
import StepperTitle from '../StepperTitle';
import StepperSubtitle from '../StepperSubtitle';

import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';
import { trackSegment } from '../../../../helpers/segment/trackingSegment';

const SchedulePaymentStep2 = React.memo(() => {
  const {
    state: { payrollPaymentDate },
    updateState,
  } = useContext(StepperContext);
  const [payrollName, setPayrollName] = useState(
    moment(new Date(payrollPaymentDate)).format('[Facturas del] DD [de] MMMM')
  );
  const { id: businessId } = useSelector(state => state.businessState.business);

  const onChangeInput = evt => {
    const { value } = evt.target;
    setPayrollName(value);
    updateState({
      payrollName: value,
    });
  };

  useEffect(() => {
    updateState({
      payrollName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_DATE_COMPLETED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item>
      <StepperTitle>Ingresa un nombre para el listado de facturas</StepperTitle>
      <StepperSubtitle>
        Por favor, ingresa la fecha en la que deseas realizar el pago.
      </StepperSubtitle>

      <TextField
        fullWidth
        label="Nombre del listado de facturas"
        id="outlined-basic"
        variant="outlined"
        color="secondary"
        onChange={onChangeInput}
        value={payrollName}
      />
    </Grid>
  );
});

export default SchedulePaymentStep2;
