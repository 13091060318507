import React, { useEffect } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import CustomButton from '../../../../components/elements/CustomButton';
import PayrollSummaryTable from '../PayrollSummaryTable';
import LoadingButton from '../../../../components/elements/LoadingButton';
import { groupBy } from '../../../../helpers/paymentsHelper';
import { SEGMENT_EVENTS } from '../../../../helpers/segment/constants';
import { trackSegment } from '../../../../helpers/segment/trackingSegment';
import { schedulePaymentStep3Styles } from './styles';
import { useSchedulePaymentStep3 } from './hooks';

const RFC_KEY = 'identifier';

const SchedulePaymentStep3 = React.memo(() => {
  const {
    currentStep,
    goBack,
    payrollPaymentDate,
    invoicesQuantities,
    setInvoicesQuantities,
    providersQuantities,
    setProvidersQuantities,
    selectedInvoices,
    totalSelectedInvoices,
    businessId,
    countryId,
    savePayrollLoading,
    handleSavePayroll,
  } = useSchedulePaymentStep3();

  const classes = schedulePaymentStep3Styles();

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      const filtered = groupBy(selectedInvoices, RFC_KEY);
      setProvidersQuantities(Object.keys(filtered).length);
      setInvoicesQuantities(selectedInvoices.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices]);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_NAME_COMPLETED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item>
      <Typography
        variant="h4"
        color="textPrimary"
        component="div"
        className={classes.title}
      >
        <Box fontWeight="fontWeightBold">Detalle de facturas por pagar</Box>
      </Typography>

      <Grid container item className={classes.resumeTable}>
        <PayrollSummaryTable
          countryId={countryId}
          invoicesTotalAmount={totalSelectedInvoices}
          payrollPaymentDate={payrollPaymentDate}
          invoicesQuantities={invoicesQuantities}
          providersQuantities={providersQuantities}
        />
      </Grid>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          {currentStep > 0 && (
            <Grid item>
              <CustomButton
                variant="simple-gray"
                color="secondary"
                size="large"
                onClick={goBack}
                className={classes.backButton}
                disabled={savePayrollLoading}
              >
                Volver
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              variant="contained"
              color="secondary"
              disabled={savePayrollLoading}
              isLoading={savePayrollLoading}
              onClick={handleSavePayroll}
            >
              Programar pago
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SchedulePaymentStep3;
