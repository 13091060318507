import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
} from '@material-ui/core';

import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../../../../helpers/constants';
import CopyIcon from '../../../../../components/icons/CopyIcon';

const useStyles = makeStyles(theme => ({
  copyButton: {
    marginTop: 48,
  },
  copyButtonIcon: {
    marginRight: 12,
    color: theme.palette.secondary.main,
  },
}));

const BankTransferDetailsTable = props => {
  const { countryId, invoicesTotalAmount } = props;
  const classes = useStyles();

  const TRANSFER_DETAILS = [
    {
      key: 'Nombre',
      value: 'XEP DIGITAL SA DE CV',
    },
    {
      key: 'Banco',
      value: 'BBVA',
    },
    {
      key: 'Numero de cuenta',
      value: '01218000117515774',
    },
    {
      key: 'Tipo de cuenta',
      value: 'CLABE',
    },
    {
      key: 'Correo electrónico',
      value: 'operaciones@xepelin.com',
    },
  ];

  const handleCopy = () => {
    const transferInfo = Object.values(TRANSFER_DETAILS)
      .map(item => item.value)
      .join('\n');
    navigator.clipboard.writeText(transferInfo);
  };

  return (
    <Grid container item>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell align="left">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                component="div"
              >
                <Box fontWeight="fontWeightBold">TOTAL A PAGAR</Box>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CountryFormatHelper
                value={invoicesTotalAmount}
                countryId={countryId}
                variant="currency"
              />
            </TableCell>
          </TableRow>
          {TRANSFER_DETAILS.map(({ key, value }) => (
            <TableRow key={key}>
              <TableCell align="left">
                <Typography variant="subtitle1" color="textPrimary">
                  {key}
                </Typography>
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container item justifyContent="flex-end" alignContent="center">
        <Button className={classes.copyButton} onClick={handleCopy}>
          <CopyIcon className={classes.copyButtonIcon} />
          <Typography variant="subtitle2" component="div">
            <Box color="secondary.main">Copiar todos los datos</Box>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

BankTransferDetailsTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  invoicesTotalAmount: PropTypes.number.isRequired,
};

export default BankTransferDetailsTable;
