import React from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import TableDatePicker from '../TableDatePicker';

const useStyles = makeStyles(theme => ({
  paymentDateLink: {
    color: theme.palette.secondary.main,
    textDecorationLine: 'underline',
    background: 'none',
    '&:hover, &:active, &:focus,': {
      background: 'none',
    },
  },
  paymentDateColumn: {
    minWidth: 115,
  },
}));

const PaymentDate = React.memo(props => {
  const { date, countryId, invoice, onChangeDatePicker } = props;

  const classes = useStyles();

  return (
    <Box className={classes.paymentDateColumn}>
      <TableDatePicker
        date={date}
        onChange={onChangeDatePicker}
        countryId={countryId}
        invoice={invoice}
      >
        <ButtonBase disableRipple className={classes.paymentDateLink}>
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={date}
              variant="longdate"
              countryId={countryId}
            />
          </Box>
        </ButtonBase>
      </TableDatePicker>
    </Box>
  );
});

PaymentDate.defaultProps = {};

PaymentDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  countryId: PropTypes.string.isRequired,
  invoice: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeDatePicker: PropTypes.func.isRequired,
};

export default PaymentDate;
