import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, makeStyles, Tooltip } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import { t13s } from '../../translationKeys';
import { handleInvoicesSelectionChange } from '../../store/actions/cartActions';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import CircleUncheckedIcon from '../icons/CircleUncheckedIcon';
import CircleCheckedIcon from '../icons/CircleCheckedIcon';

const useStyles = makeStyles(theme => ({
  folio: {
    maxWidth: 50,
  },
  leftSpace: {
    borderLeft: '4px solid transparent',
  },
  fireIcon: {
    color: theme.palette.text.contrast2,
    fontSize: 18,
  },
}));

const InvoiceTableStepOne = React.memo(
  ({
    countryId,
    handleChangePage,
    handleChangeSort,
    tableData,
    fetchCartInvoicesIsLoading,
  }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { pagination } = useSelector(state => state.cartState);

    const handleSelectionChange = selectedInvoices => {
      const newSelectedInvoices = selectedInvoices.map(invoice => {
        const baseInvoice = { ...invoice };
        delete baseInvoice.tableData;
        return baseInvoice;
      });
      dispatch(
        handleInvoicesSelectionChange({ selectedInvoices: newSelectedInvoices })
      );
    };

    const getColumns = () => {
      const columns = [
        {
          title: 'Folio',
          field: 'folio',
          type: 'string',
          width: '80px',
          align: 'left',
          render: invoice => (
            <Typography
              variant="body2"
              color="textPrimary"
              data-qa="invoice-table-step-one-folio-row-data"
            >
              {invoice?.folio}
            </Typography>
          ),
          customSort: () => handleChangeSort('folio'),
        },
        {
          title: 'Nombre Pagador',
          field: 'payerName',
          type: 'string',
          align: 'left',
          render: invoice => (
            <Typography variant="body2" color="textPrimary">
              <Box
                fontWeight="fontWeightBold"
                data-qa="invoice-table-step-one-nombre-pagador-row-data"
              >
                {invoice?.payerName ? invoice?.payerName.toUpperCase() : '-'}
              </Box>
            </Typography>
          ),
        },
        {
          title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Pagador`,
          field: 'payerIdentifier',
          type: 'string',
          width: '160px',
          align: 'left',
          render: invoice => (
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              noWrap
            >
              <Box
                fontWeight="fontWeightBold"
                data-qa="invoice-table-step-one-identificador-row-data"
              >
                {invoice?.payerIdentifier}
              </Box>
            </Typography>
          ),
        },
        {
          title: 'Fecha emisión',
          field: 'issueDate',
          type: 'date',
          width: '160px',
          align: 'left',
          render: invoice => (
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              noWrap
            >
              <Box
                fontWeight="fontWeightBold"
                data-qa="invoice-table-step-one-fecha-emision-row-data"
              >
                <CountryFormatHelper
                  value={invoice?.issueDate}
                  variant="longdate"
                  countryId={countryId}
                />
              </Box>
            </Typography>
          ),
          customSort: () => handleChangeSort('issueDate'),
        },
        {
          title: 'Monto Total',
          field: 'amount',
          type: 'numeric',
          width: '180px',
          render: invoice => (
            <Box display="inline-flex">
              {invoice.totalAmountCreditNotes ? (
                <Tooltip
                  title={
                    <>
                      Monto nota de crédito: $
                      <CountryFormatHelper
                        value={invoice.totalAmountCreditNotes}
                        countryId={countryId}
                        variant="currency"
                      />
                    </>
                  }
                >
                  <WarningRoundedIcon color="error" fontSize="small" />
                </Tooltip>
              ) : null}
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                noWrap
              >
                <Box fontWeight="fontWeightBold">
                  <CountryFormatHelper
                    value={invoice?.amount}
                    countryId={countryId}
                    variant="currency"
                    dataQa="invoice-table-step-one-monto-total-row-data"
                  />
                </Box>
              </Typography>
            </Box>
          ),
          customSort: () => handleChangeSort('amount'),
        },
      ];

      if (countryId === 'CL') {
        const docTypeColumn = {
          title: 'Tipo',
          field: 'docType',
          type: 'string',
          width: '100px',
          render: invoice => (
            <Typography variant="body1" color="textSecondary" component="div">
              <Box
                fontWeight="fontWeightBold"
                data-qa="invoice-table-step-one-tipo-row-data"
              >
                {invoice.docType}
              </Box>
            </Typography>
          ),
        };

        columns.splice(4, 0, docTypeColumn);
      }

      if (countryId === 'MX') {
        const paymentMethodColumn = {
          title: 'Método de Pago',
          field: 'paymentMethod',
          type: 'string',
          width: '150px',
          render: invoice => (
            <Typography variant="body1" color="textSecondary" component="div">
              <Box
                fontWeight="fontWeightBold"
                data-qa="invoice-table-step-one-metodo-de-pago-row-data"
              >
                {invoice.paymentMethod}
              </Box>
            </Typography>
          ),
        };

        columns.splice(4, 0, paymentMethodColumn);
      }

      return columns;
    };

    return (
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        columns={getColumns()}
        data={tableData}
        isLoading={fetchCartInvoicesIsLoading}
        localization={{
          pagination: {
            labelDisplayedRows: '{count} Facturas en total',
            labelRowsSelect: 'facturas',
            labelRowsPerPage: 'Facturas por página',
          },
          toolbar: {
            nRowsSelected: count =>
              count > 1
                ? `${count} facturas seleccionadas`
                : '1 factura seleccionada',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay facturas para mostrar',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
        }}
        onSelectionChange={handleSelectionChange}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
        options={{
          showTitle: false,
          search: false,
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          selection: true,
          sorting: true,
          pageSize: pagination.pageSize,
          maxBodyHeight: 440,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showSelectAllCheckbox: true,
          selectionProps: invoice => ({
            icon: (
              <CircleUncheckedIcon
                data-qa={`invoice-table-step-one-check-icon-${invoice?.tableData?.id}`}
              />
            ),
            checkedIcon: (
              <CircleCheckedIcon
                data-qa={`invoice-table-step-one-check-icon-${invoice?.tableData?.id}`}
              />
            ),
          }),
          headerSelectionProps: {
            icon: (
              <CircleUncheckedIcon
                className={classes.leftSpace}
                data-qa="invoice-table-step-one-check-icon"
              />
            ),
            checkedIcon: (
              <CircleCheckedIcon
                className={classes.leftSpace}
                data-qa="invoice-table-step-one-check-icon"
              />
            ),
            indeterminateIcon: (
              <CircleUncheckedIcon
                className={classes.leftSpace}
                data-qa="invoice-table-step-one-check-icon"
              />
            ),
          },
          rowStyle: invoice => {
            const {
              tableData: { checked = false },
            } = invoice;
            return {
              backgroundColor: checked ? '#F3F2F5' : '',
              borderLeft: checked
                ? '8px solid #f77240'
                : '8px solid transparent',
            };
          },
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination.totalData}
              page={pagination.page - 1}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props.rowsPerPage)
              }
            />
          ),
        }}
      />
    );
  }
);

InvoiceTableStepOne.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf({
    amount: PropTypes.number.isRequired,
    averageDaysLate: PropTypes.number.isRequired,
    expirationDate: PropTypes.string.isRequired,
    extraData: PropTypes.arrayOf({
      code: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    folio: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    issueDate: PropTypes.string.isRequired,
    payerIdentifier: PropTypes.string.isRequired,
  }).isRequired,
  fetchCartInvoicesIsLoading: PropTypes.bool.isRequired,
};

export default InvoiceTableStepOne;
