import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LatePaymentIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx="24" cy="24" r="24" fill="#FFEDED" />
      <path
        d="M20.27 15L15 20.27V27.73L20.27 33H27.73C29.5 31.24 33 27.73 33 27.73V20.27L27.73 15H20.27ZM21.1 17H26.9L31 21.1V26.9L26.9 31H21.1L17 26.9V21.1L21.1 17ZM23 27H25V29H23V27ZM23 19H25V25H23V19Z"
        fill="#F87348"
      />
    </SvgIcon>
  );
};

export default LatePaymentIcon;
