import React from 'react';
import { useSelector } from 'react-redux';
import useInboxTable from './useInboxTable';
import { useSharedTableHeaders } from '../commons';
import filterHeaderByCountry from '../../utils/filterHeaderByCountry';
import { PaymentDate } from '../../../commons/components';

const useInboxTableHeaders = () => {
  const { countryId } = useSelector(state => state.businessState.business);
  const { onChangeDatePicker } = useInboxTable();

  const {
    folio,
    supplierName,
    identifier,
    issueDate,
    paymentMethod,
    totalAmount,
  } = useSharedTableHeaders();

  const paymentDate = {
    title: 'Fecha de Pago',
    field: 'paymentDate',
    type: 'date',
    width: '100%',
    align: 'left',
    render: invoice => {
      const { paymentDate: paymentDateValue } = invoice.paymentDetail;
      if (!paymentDateValue) return null;
      return (
        <PaymentDate
          date={paymentDateValue}
          onChangeDatePicker={onChangeDatePicker}
          countryId={countryId}
          invoice={invoice}
        />
      );
    },
  };

  const headers = {
    folio,
    supplierName,
    identifier,
    issueDate,
    paymentDate,
    paymentMethod,
    totalAmount,
  };

  return filterHeaderByCountry({ countryId, headers });
};

export default useInboxTableHeaders;
