import React from 'react';
import { TourProvider } from '@reactour/tour';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import GuidedTourComponent from './GuidedTourComponent';
import { palette } from '../../../../theme/palette';

const overrideStyles = {
  maskArea: (base, { width, height, x, y }) => {
    const reduce = 20;
    return {
      ...base,
      width: width - reduce,
      height: height - reduce,
      x: x + reduce / 2,
      y: y + reduce / 2,
    };
  },
  popover: base => ({
    ...base,
    background: palette.primary.dark,
    color: '#fff',
    borderRadius: 8,
  }),
};

const GuidedTour = React.memo(props => {
  const { children, name, ...args } = props;

  const handleAfterOpen = target => {
    disableBodyScroll(target);
    localStorage.setItem(name, true);
  };

  const handleBeforeClose = target => {
    enableBodyScroll(target);
  };

  return (
    <TourProvider
      afterOpen={handleAfterOpen}
      beforeClose={handleBeforeClose}
      showBadge={false}
      showNavigation={false}
      showCloseButton={false}
      styles={overrideStyles}
      onClickMask={() => null}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...args}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <GuidedTourComponent {...props}>{children}</GuidedTourComponent>
    </TourProvider>
  );
});

GuidedTour.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  name: PropTypes.string.isRequired,
};

export default GuidedTour;
