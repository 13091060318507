import React, { useState, useContext, useEffect } from 'react';
import { createMuiTheme, ThemeProvider, Grid } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { typography } from '../../../../theme/typography';
import { palette } from '../../../../theme/palette';
import { StepperContext } from '../StepperFlow';
import StepperTitle from '../StepperTitle';
import StepperSubtitle from '../StepperSubtitle';
import { isWeekend, blockBusinessDays } from '../../../../helpers/dateHelpers';

const SchedulePaymentStep1 = React.memo(() => {
  const { updateState } = useContext(StepperContext);
  const [selectedDate, setSelectedDate] = useState(moment().format());

  const outerTheme = createMuiTheme({
    typography,
    palette: {
      ...palette,
      primary: { ...palette.secondary },
      secondary: { ...palette.primary },
    },
  });

  const handleOnChange = date => {
    const formatedDate = date.format();
    setSelectedDate(formatedDate);
  };

  useEffect(() => {
    updateState({
      payrollPaymentDate: selectedDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Grid container item>
      <StepperTitle>Selecciona una fecha de pago</StepperTitle>
      <StepperSubtitle>
        Por favor, ingresa la fecha en la que deseas realizar el pago.
      </StepperSubtitle>

      <ThemeProvider theme={outerTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            disableToolbar
            disablePast
            autoOk
            orientation="landscape"
            variant="static"
            openTo="date"
            value={selectedDate}
            onChange={handleOnChange}
            shouldDisableDate={date =>
              isWeekend(date) || blockBusinessDays(date, 3)
            }
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Grid>
  );
});

export default SchedulePaymentStep1;
