import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StepperContainer } from '../StepperFlow';
import { PayInvoicesStep1 } from './PayInvoicesStep1';
import { PayInvoicesStep2 } from './PayInvoicesStep2';
import PayInvoicesSuccess from './PayInvoicesSuccess';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
  },
  paper: {
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const PayInvoicesStepper = props => {
  const { open, closeModal, dataInvoicesToPay } = props;
  const classes = useStyles();

  const PAY_STEPS = [
    {
      label: 'Resumen',
      // eslint-disable-next-line react/jsx-props-no-spreading
      component: <PayInvoicesStep1 {...dataInvoicesToPay} />,
      nextButtonText: 'Pagar ahora',
    },
    {
      label: 'Método de pago',
      // eslint-disable-next-line react/jsx-props-no-spreading
      component: <PayInvoicesStep2 {...dataInvoicesToPay} />,
      customButtons: true,
    },
  ];

  const PAY_STEPS_COMPLETED = {
    component: <PayInvoicesSuccess />,
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      closeAfterTransition
      disablePortal
      className={classes.modal}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade style={{ padding: 0 }} in={open}>
        <div className={classes.paper}>
          <StepperContainer
            title="Pago de facturas"
            closeModal={closeModal}
            steps={PAY_STEPS}
            stepCompleted={PAY_STEPS_COMPLETED}
          />
        </div>
      </Fade>
    </Modal>
  );
};

PayInvoicesStepper.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dataInvoicesToPay: PropTypes.shape({
    invoicesTotalAmount: PropTypes.number.isRequired,
    payrollPaymentDate: PropTypes.string.isRequired,
    invoicesQuantities: PropTypes.number.isRequired,
    providersQuantities: PropTypes.number.isRequired,
  }).isRequired,
};

export default PayInvoicesStepper;
