import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../components/elements/CustomButton';
import CheckIcon from '../../../../components/icons/CheckIcon';
import { PAYMENTS_TABS } from '../../../../helpers/constants';
import { setActiveTab } from '../../../../store/actions/paymentsActions';
import StepperTitle from '../StepperTitle';
import StepperSubtitle from '../StepperSubtitle';
import { schedulePaymentSuccessStyles } from './styles';

const SchedulePaymentSuccess = React.memo(() => {
  const dispatch = useDispatch();
  const classes = schedulePaymentSuccessStyles();

  const handleSuccessButton = () =>
    dispatch(setActiveTab(PAYMENTS_TABS.SCHEDULED));

  return (
    <Grid container item direction="column">
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        className={classes.check}
      >
        <CheckIcon className={classes.checkIcon} />
      </Grid>

      <StepperTitle>¡Pago programado con éxito!</StepperTitle>
      <StepperSubtitle>
        Hemos enviado tu solicitud a los responsables de aprobar esta operación.
        Una vez aprobada, podrás realizar el pago de esta.
      </StepperSubtitle>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          <CustomButton
            variant="all-orange"
            color="secondary"
            size="large"
            onClick={handleSuccessButton}
          >
            Ir a pagos
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default SchedulePaymentSuccess;
