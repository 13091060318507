import React, { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../../../components/elements/CustomButton';
import CheckIcon from '../../../../../components/icons/CheckIcon';
import { StepperTitle, StepperSubtitle } from '../../../../commons/components';

const useStyles = makeStyles(theme => ({
  buttonsRoot: {
    marginTop: 0,
  },
  check: {
    width: 48,
    height: 48,
    borderRadius: '100%',
    background: alpha(theme.palette.success.main, 0.2),
    marginBottom: 32,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));

const OnboardSuccess = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => {
      history.go(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item direction="column">
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        className={classes.check}
      >
        <CheckIcon className={classes.checkIcon} />
      </Grid>

      <StepperTitle>
        ¡Configuración de cuentas realizada con éxito!
      </StepperTitle>
      <StepperSubtitle>
        Ya estás listo para disfrutar de la forma más rápida y simple para
        realizar los pagos a tus proveedores.
      </StepperSubtitle>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          <CustomButton
            variant="all-orange"
            color="secondary"
            size="large"
            onClick={() => history.go(0)}
          >
            Ir a mis facturas
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardSuccess;
